import React from 'react'
import { Grid, TextField } from '@mui/material'
import { companyNameExists } from '../../../utils'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { VisibilityCheckbox } from '../VisibilityCheckbox/VisibilityCheckbox'

const messages = defineMessages({
    brandsInputLabel: {
        defaultMessage: 'Associated brand',
        description: 'Label for brand name input field'
    },
    brandNameRequiredLabel: {
        defaultMessage: 'Brand name is required.',
        description: 'Label for button to add brand'
    },
    brandExistsLabel: {
        defaultMessage: 'Brand name already exists.',
        description: 'Label for existing brand error'
    }
})

export interface BrandFormInputs {
    name: string
    isPublic: boolean
}

interface BrandInputProps {
    getAlreadyExists?: null | ((value: string) => boolean)
    fullsize?: boolean
}

const BrandInput: React.FC<BrandInputProps> = ({
    getAlreadyExists = null,
    fullsize = false
}) => {
    const intl = useIntl()

    const {
        control,
        formState: { errors, defaultValues }
    } = useFormContext()

    return (
        <>
            <Grid item xs={12}>
                <VisibilityCheckbox />
            </Grid>
            <Grid item xs={12} md={fullsize ? 12 : 6}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            spellCheck="false"
                            id="brand-name-input"
                            variant="outlined"
                            label={intl.formatMessage(
                                messages.brandsInputLabel
                            )}
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                            FormHelperTextProps={{
                                variant: 'standard'
                            }}
                        />
                    )}
                    name="name"
                    rules={{
                        required: intl.formatMessage(
                            messages.brandNameRequiredLabel
                        ),
                        validate: {
                            noWhiteSpace: (value) => {
                                if (!value.trim())
                                    return intl.formatMessage(
                                        messages.brandNameRequiredLabel
                                    )
                            },
                            doesntAlreadyExistInList: (value) => {
                                return getAlreadyExists &&
                                    getAlreadyExists(value)
                                    ? intl.formatMessage(
                                          messages.brandExistsLabel
                                      )
                                    : null
                            },
                            doesntAlreadyExistInDb: async (value) => {
                                const valueChanged =
                                    value !== defaultValues.code
                                if (valueChanged) {
                                    const existsInDb = await companyNameExists(
                                        value.trim()
                                    )
                                    return existsInDb
                                        ? intl.formatMessage(
                                              messages.brandExistsLabel
                                          )
                                        : null
                                }
                            }
                        }
                    }}
                    control={control}
                />
            </Grid>
        </>
    )
}

export default BrandInput
