import {
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Box
} from '@mui/material'
import React from 'react'
import {
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'

export enum SignupMethodModalVariant {
    Setup = 'setup',
    Enable = 'enable',
    Disable = 'disable'
}

const messages = defineMessages({
    setupButtonText: {
        defaultMessage: 'Setup',
        description: 'Label for button to setup signup method'
    },
    enableButtonText: {
        defaultMessage: 'Enable',
        description: 'Label for button to enable signup method'
    },
    disableButtonText: {
        defaultMessage: 'Disable',
        description: 'Label for button to disable signup method'
    }
})

const actionButtonText: Record<SignupMethodModalVariant, MessageDescriptor> = {
    [SignupMethodModalVariant.Setup]: messages.setupButtonText,
    [SignupMethodModalVariant.Enable]: messages.enableButtonText,
    [SignupMethodModalVariant.Disable]: messages.disableButtonText
}

interface SignupMethodModalContentProps {
    dialogAction: any
    title: string
    SignupMethodModalVariant: SignupMethodModalVariant
    loading?: boolean
}

export const SignupMethodModalContent: React.FC<
    SignupMethodModalContentProps
> = ({ dialogAction, title, children, SignupMethodModalVariant, loading }) => {
    const intl = useIntl()
    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <LoadingButton
                        disabled={loading}
                        loading={loading}
                        loader={
                            <CircularProgress
                                data-testid="form-save-loader"
                                size={24}
                                sx={{
                                    verticalAlign: 'middle'
                                }}
                            />
                        }
                        fixWidth
                        variant="contained"
                        color="primary"
                        onClick={dialogAction}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {intl.formatMessage(
                                actionButtonText[SignupMethodModalVariant]
                            )}
                        </CoreTypography>
                    </LoadingButton>
                </Box>
            </DialogActions>
        </>
    )
}
