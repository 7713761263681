import React, { useRef } from 'react'
import { Grid, TextField } from '@mui/material'
import { companyNameExists } from '../../../utils'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { VisibilityCheckbox, StatusSelector } from '../index'
import { CompanyStatus } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    companyNameInputLabel: {
        defaultMessage: 'Company name',
        description: 'Label for company name input field'
    },
    companySeatsInputLabel: {
        defaultMessage: 'Company seats',
        description: 'Label for company seats input field'
    },
    companyNameRequired: {
        defaultMessage: 'Company name is required.',
        description: 'Required error message for company name'
    },
    companySeatsRequired: {
        defaultMessage: 'A non-zero integer is required.',
        description: 'Required error message for company seats'
    },
    companyNameExists: {
        defaultMessage: 'A company with this name already exists.',
        description: 'Error message shown when a company name already exists.'
    }
})

export interface CompanyFormInputs {
    name: string
    seatsAllowed: string
    isPublic: boolean
    status: CompanyStatus
}

// integer > 0
const seatsAllowedRegex = /^[1-9]\d*$/

interface CompanyDetailsInputProps {
    fullsize?: boolean
}

//Company name, seats, status, and visibility inputs with validation
//Component or parent component must be wrapped by FormProvider
export const CompanyDetailsInput: React.FC<CompanyDetailsInputProps> = ({
    fullsize = false
}) => {
    const intl = useIntl()
    const {
        control,
        formState: { errors },
        getValues
    } = useFormContext()

    const defaultNameValue = useRef(getValues()?.name)

    return (
        <>
            <Grid item xs={12} md={fullsize ? 12 : 7}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            required
                            spellCheck="false"
                            id="company-name-input"
                            variant="outlined"
                            label={intl.formatMessage(
                                messages.companyNameInputLabel
                            )}
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                            FormHelperTextProps={{
                                variant: 'standard'
                            }}
                        />
                    )}
                    name="name"
                    rules={{
                        required: intl.formatMessage(
                            messages.companyNameRequired
                        ),
                        validate: {
                            notJustWhiteSpace: (value) => {
                                if (!value.trim())
                                    return intl.formatMessage(
                                        messages.companyNameRequired
                                    )
                            },
                            doesntAlreadyExist: async (value) => {
                                //if value hasn't updated, don't verify if it exists in db
                                if (
                                    value.trim() ===
                                    defaultNameValue.current?.trim()
                                ) {
                                    return null
                                }
                                const exists = await companyNameExists(
                                    value.trim()
                                )
                                return exists
                                    ? intl.formatMessage(
                                          messages.companyNameExists
                                      )
                                    : null
                            }
                        }
                    }}
                    control={control}
                />
            </Grid>
            <Grid item xs={12} md={fullsize ? 12 : 7}>
                <StatusSelector />
            </Grid>
            <Grid item xs={12} md={fullsize ? 12 : 7}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            required
                            spellCheck="false"
                            id="company-seats-input"
                            variant="outlined"
                            label={intl.formatMessage(
                                messages.companySeatsInputLabel
                            )}
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            error={!!errors?.seatsAllowed}
                            helperText={errors?.seatsAllowed?.message}
                            FormHelperTextProps={{
                                variant: 'standard'
                            }}
                        />
                    )}
                    name="seatsAllowed"
                    rules={{
                        required: intl.formatMessage(
                            messages.companySeatsRequired
                        ),
                        pattern: {
                            value: seatsAllowedRegex,
                            message: intl.formatMessage(
                                messages.companySeatsRequired
                            )
                        }
                    }}
                    control={control}
                />
            </Grid>
            <Grid item xs={12}>
                <VisibilityCheckbox />
            </Grid>
        </>
    )
}
