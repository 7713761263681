import { useAppSelector } from '@thriveglobal/thrive-web-core'
import jwtDecode from 'jwt-decode'
import { useMemo } from 'react'

export const usePermissions = (requiredPermissions = ['write:identity']) => {
    const authState = useAppSelector((state) => state.auth)
    const hasRequiredPermissions = useMemo(() => {
        try {
            const decodedThriveToken = jwtDecode(authState.thriveToken) as any
            const permissions = decodedThriveToken.authorization.permissions
            return requiredPermissions.every((permission) =>
                permissions.some((el) => el.scopes?.includes(permission))
            )
        } catch (e) {
            return false
        }
    }, [authState.thriveToken, requiredPermissions])

    return { hasRequiredPermissions }
}
