import React from 'react'
import { TextField, Stack } from '@mui/material'
import { signupCodeExists } from '../../../utils'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'

const messages = defineMessages({
    codeInputLabel: {
        defaultMessage: 'Code',
        description: 'Label for signup code input'
    },
    codeRequiredLabel: {
        defaultMessage: 'Sign-up code name is required.',
        description: 'Label for signup code input'
    },
    codeExistsLabel: {
        defaultMessage: 'A sign-up code with this name already exists.',
        description: 'Label for existing signup code error'
    },
    startDateInputLabel: {
        defaultMessage: 'Start date',
        description: 'Label for signup code start date input'
    },
    endDateInputLabel: {
        defaultMessage: 'End date',
        description: 'Label for signup code end date input'
    }
})

export interface SignupCodeFormInputs {
    code: string
    startDate: string
    endDate: string
}

interface SignupCodeInputProps {
    getAlreadyExists?: null | ((value: string) => boolean)
    canEditCodeName?: boolean
}
export const SignupCodeInput: React.FC<SignupCodeInputProps> = ({
    getAlreadyExists = null,
    canEditCodeName = true
}) => {
    const intl = useIntl()
    const {
        control,
        formState: { errors, defaultValues },
        setValue
    } = useFormContext()

    return (
        <Stack direction="column" spacing={2}>
            <Controller
                render={({ field }) => (
                    <TextField
                        disabled={!canEditCodeName}
                        fullWidth
                        required
                        spellCheck="false"
                        id="code-name-input"
                        variant="outlined"
                        label={intl.formatMessage(messages.codeInputLabel)}
                        value={field.value}
                        inputRef={field.ref}
                        onChange={(e) => {
                            field.onChange(e)
                            setValue('code', e.target.value.toLowerCase())
                        }}
                        error={!!errors?.code}
                        helperText={errors?.code?.message}
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                name="code"
                rules={{
                    required: intl.formatMessage(messages.codeRequiredLabel),
                    validate: {
                        notJustWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.codeRequiredLabel
                                )
                        },
                        doesntAlreadyExistInList: (value) => {
                            return getAlreadyExists && getAlreadyExists(value)
                                ? intl.formatMessage(messages.codeExistsLabel)
                                : null
                        },
                        doesntAlreadyExistInDb: async (value) => {
                            const valueChanged = value !== defaultValues.code
                            if (valueChanged) {
                                const existsInDb = await signupCodeExists(
                                    value.trim()
                                )
                                return existsInDb
                                    ? intl.formatMessage(
                                          messages.codeExistsLabel
                                      )
                                    : null
                            }
                        }
                    }
                }}
                control={control}
            />
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        type="date"
                        spellCheck="false"
                        id="start-date-input"
                        variant="outlined"
                        value={field.value.substring(0, 10)}
                        inputRef={field.ref}
                        onChange={(e) => {
                            field.onChange(e)
                            const input = e.target as HTMLInputElement
                            setValue(
                                'startDate',
                                input.valueAsDate?.toISOString()
                            )
                        }}
                        label={intl.formatMessage(messages.startDateInputLabel)}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
                name="startDate"
                control={control}
            />
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        type="date"
                        spellCheck="false"
                        id="end-date-input"
                        variant="outlined"
                        value={field.value.substring(0, 10)}
                        inputRef={field.ref}
                        onChange={(e) => {
                            field.onChange(e)
                            const input = e.target as HTMLInputElement
                            setValue(
                                'endDate',
                                input.valueAsDate?.toISOString()
                            )
                        }}
                        label={intl.formatMessage(messages.endDateInputLabel)}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
                name="endDate"
                control={control}
            />
        </Stack>
    )
}
