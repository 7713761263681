import { Stack, TextField } from '@mui/material'
import React from 'react'
import { validateUuidShape } from '../../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { useFormContext, Controller } from 'react-hook-form'

const messages = defineMessages({
    tenantIdInputLabel: {
        defaultMessage: 'Nice Tenant Id',
        description: 'Label for nice tenant ID input'
    },
    tenantIdRequiredLabel: {
        defaultMessage: 'Nice Tenant Id is required.',
        description: 'Label for nice tenant ID required error'
    },
    tenantIdInvalidLabel: {
        defaultMessage: 'Invalid Nice Tenant Id.',
        description: 'Label for nice tenant ID required error'
    }
} as const)

export interface NiceConfigurationFormInputs {
    niceTenantId: string
}

export const NiceConfigurationInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <Stack spacing={2}>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="idp-tenant-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.niceTenantId}
                        onChange={field.onChange}
                        label={intl.formatMessage(messages.tenantIdInputLabel)}
                        helperText={errors?.niceTenantId?.message}
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(
                        messages.tenantIdRequiredLabel
                    ),
                    validate: {
                        validUuid: (value) => {
                            return validateUuidShape(value)
                                ? null
                                : intl.formatMessage(
                                      messages.tenantIdInvalidLabel
                                  )
                        }
                    }
                }}
                name="niceTenantId"
            />
        </Stack>
    )
}

export default React.memo(NiceConfigurationInput)
