import React from 'react'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import {
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    FormHelperText
} from '@mui/material'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    subscriptionStatusLabel: {
        defaultMessage: 'Status',
        description: 'Label for status field'
    },
    enabledLabel: {
        defaultMessage: 'Enabled',
        description: 'Label for enabled subscription status option'
    },
    disabledLabel: {
        defaultMessage: 'Disabled',
        description: 'Label for enabled subscription status option'
    },
    subscriptionStatusRequiredLabel: {
        defaultMessage: 'Subscription status is required.',
        description: 'Label for enabled subscription status option'
    }
})

export const SubscriptionStatusSelector: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()
    return (
        <>
            <FormControl
                variant="outlined"
                fullWidth
                required
                error={!!errors?.enabled}
            >
                <InputLabel id="subscription-status-select-label">
                    {intl.formatMessage(messages.subscriptionStatusLabel)}
                </InputLabel>
                <Controller
                    render={({ field }) => (
                        <Select
                            fullWidth
                            labelId="subscription-status-select-label"
                            id="subscription-status-select"
                            value={Number(field.value)}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            label={intl.formatMessage(
                                messages.subscriptionStatusLabel
                            )}
                        >
                            <MenuItem
                                value={1}
                                aria-label={intl.formatMessage(
                                    messages.enabledLabel
                                )}
                            >
                                {intl.formatMessage(messages.enabledLabel)}
                            </MenuItem>
                            <MenuItem
                                value={0}
                                aria-label={intl.formatMessage(
                                    messages.disabledLabel
                                )}
                            >
                                {intl.formatMessage(messages.disabledLabel)}
                            </MenuItem>
                        </Select>
                    )}
                    name="enabled"
                    control={control}
                    rules={{
                        required: intl.formatMessage(
                            messages.subscriptionStatusRequiredLabel
                        )
                    }}
                />
                <FormHelperText variant="standard">
                    {errors?.enabled?.message}
                </FormHelperText>
            </FormControl>
        </>
    )
}
