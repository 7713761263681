import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { LicenseType } from '../../../graphql/generated/autogenerated'
import {
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    FormHelperText
} from '@mui/material'

const messages = defineMessages({
    licenseTypeLabel: {
        defaultMessage: 'License type',
        description: 'Label for license type field'
    },
    standardLicenseLabel: {
        defaultMessage: 'Standard',
        description: 'Label for standard license option'
    },
    troLicenseLabel: {
        defaultMessage: 'Thrive Reset Only',
        description: 'Label for Thrive Reset Only license option'
    },
    licenseTypeRequiredLabel: {
        defaultMessage: 'License type is required.',
        description: 'Label for license type required error'
    }
})

export const LicenseTypeSelector: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()
    return (
        <>
            <FormControl
                variant="outlined"
                fullWidth
                error={!!errors?.license}
                required
            >
                <InputLabel id="license-select-label">
                    {intl.formatMessage(messages.licenseTypeLabel)}
                </InputLabel>
                <Controller
                    render={({ field }) => (
                        <Select
                            fullWidth
                            labelId="license-select-label"
                            id="license-select"
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            label={intl.formatMessage(
                                messages.licenseTypeLabel
                            )}
                        >
                            <MenuItem
                                value={LicenseType.Standard}
                                aria-label={intl.formatMessage(
                                    messages.standardLicenseLabel
                                )}
                            >
                                {intl.formatMessage(
                                    messages.standardLicenseLabel
                                )}
                            </MenuItem>
                            <MenuItem
                                value={LicenseType.ThriveResetOnly}
                                aria-label={intl.formatMessage(
                                    messages.troLicenseLabel
                                )}
                            >
                                {intl.formatMessage(messages.troLicenseLabel)}
                            </MenuItem>
                        </Select>
                    )}
                    name="license"
                    control={control}
                    rules={{
                        required: intl.formatMessage(
                            messages.licenseTypeRequiredLabel
                        )
                    }}
                />
                <FormHelperText variant="standard">
                    {errors?.license?.message}
                </FormHelperText>
            </FormControl>
        </>
    )
}
